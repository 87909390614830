<template>
  <div v-if="Array.isArray(valueArray)">{{valueArray.join(", ")}}</div>
</template>
<script>
export default {
  props: ["header", "row", "currentTable"],
  computed: {
    valueArray() {
      return this.row[this.header];
    },
  },
};
</script>